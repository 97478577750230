var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          {
            attrs: {
              visible: _vm.dialogVisible,
              width: "35%",
              "custom-class": "submitted-dialog",
              "close-on-click-modal": false,
              "show-close": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          _vm.$listeners
        ),
        [
          _c("div", [
            _c("div", { staticClass: "tip" }, [
              _vm._v("Your contract has been submitted!"),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "Date and time created: " +
                  _vm._s(_vm.responseData.createTime) +
                  " HRS"
              ),
            ]),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.downloadPdf },
                  },
                  [_vm._v(" Download as PDF ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", round: "" },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v(" Done ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }